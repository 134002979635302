import React, { useContext } from 'react';
import { Modal, ModalProps, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getActiveFacets } from '../../utils/helpers';
import SearchActiveFacetList from './SearchActiveFacetList';
import SearchFacets from './SearchFacets';
import { SearchListingContext } from './SearchListingContext';

interface Props extends ModalProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FacetModal = function (props: Props) {
  const context = useContext(SearchListingContext);
  const { t } = useTranslation();

  if (!context) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!'
    );
  }

  const { data, loaded, facetModalActive, setFacetModalActive } = context || {};
  const { pager, facets = {} } = data || {};

  const handleClose = () => {
    setFacetModalActive(false);
  };

  const activeFacets = getActiveFacets(facets);

  return (
    <Modal fullscreen show={facetModalActive} onHide={props.onHide || handleClose} {...props}>
      <Modal.Header className="d-flex flex-column gap-4 px-3 pt-3 pb-0 border-bottom-0">
        <Modal.Title as="h3" className="align-self-start">
          {t('Filter By')}
        </Modal.Title>
        <div>
          {activeFacets.length > 0 && (
            <SearchActiveFacetList
              className="ms-0 ps-0"
              activeFacets={activeFacets}
              onFacetRemove={props.onChange}
            />
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        {!loaded && (
          <div className="text-center">
            <Spinner animation="border" role="status" />
          </div>
        )}
        {loaded && facets && <SearchFacets onChange={props.onChange} />}
      </Modal.Body>
      <Modal.Footer className="text-white bg-dark justify-content-center">
        {!loaded && <Spinner animation="border" role="status" size="sm" />}
        {loaded && pager && (
          <div
            role="button"
            tabIndex={0}
            className="text-center w-100"
            onKeyDown={() => {}} // TODO: add this: Visible, non-interactive elements with click handlers must have at least one keyboard listener.
            onClick={handleClose}
          >
            {t('View result', { count: pager.total_items })}
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

FacetModal.displayName = 'SearchListing.FacetModal';

export default FacetModal;
