import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { styled } from '@linaria/react';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import Chip from '../chip/Chip';
import ClearAll from './ClearAll';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  activeFacets: FacetLink[];
  onFacetRemove: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ChipList = styled.ul`
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: 2rem;
  padding-left: 2rem;
  max-width: 100%;
  width: auto;

  li {
    list-style: none;
    margin-bottom: 0.5rem;
  }

  & > li + li {
    margin-left: 0.5rem;
  }
`;
const StyledCloseIcon = styled(Icon)`
  background-color: #fff;
  border-radius: 50%;
  padding: 0.25rem;
`;
const RemoveIcon = <StyledCloseIcon path={mdiClose} size={1} color="#2b2b2b" />;

const SearchActiveFacetList: React.FC<Props> = function ({
  activeFacets,
  onFacetRemove,
  ...props
}: Props) {
  const { t } = useTranslation();
  return (
    <ChipList {...props}>
      {activeFacets.map(activeFacet => {
        if (activeFacet) {
          const facetAria = t('Remove from active filters.', { value: activeFacet.values.value });
          return (
            <li key={`facet-${activeFacet.raw_value}`}>
              <Chip
                aria-label={facetAria}
                label={activeFacet.values.value}
                icon={RemoveIcon}
                onClick={onFacetRemove as any}
                onDelete={onFacetRemove as any}
                data-url={activeFacet.url}
              />
            </li>
          );
        }
        return null;
      })}
      <li>
        <ClearAll onClick={onFacetRemove as any}>{t('Clear All')}</ClearAll>
      </li>
    </ChipList>
  );
};

export default SearchActiveFacetList;
