import React, { useContext } from 'react';
import Pager from '../pager/Pager';
import { SearchListingContext } from './SearchListingContext';

interface SearchPagerProps {
  serverData?: DrupalSearch;
}

const Pagination: React.FC<SearchPagerProps> = function ({ serverData }) {
  const context = useContext(SearchListingContext);

  if (!context && !serverData) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!'
    );
  }

  const pager = serverData?.pager || context?.data?.pager || ({} as SearchPager);

  return <Pager pager={pager} />;
};

Pagination.displayName = 'SearchListing.SearchPagination';

export default Pagination;
