import { useLocation } from '@reach/router';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useContext } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { SearchListingContext } from '../search_listing/SearchListingContext';

interface SearchPagerProps {
  pager?: SearchPager;
}

const Pager: React.FC<SearchPagerProps> = function ({ pager }) {
  const location = useLocation();
  const context = useContext(SearchListingContext);

  if (!pager) {
    return null;
  }

  const active = pager.current_page;
  const activeDisplay = active + 1;
  const totalPages = pager.total_pages;
  let start = activeDisplay;
  const max = totalPages <= 4 ? totalPages - 1 : 4;
  if (activeDisplay <= max) {
    start = 1;
  }
  if (activeDisplay >= totalPages - max) {
    start = totalPages - max;
  }
  const items = [];

  const showFirst = totalPages - 1 > max && activeDisplay > max;
  const showLast = totalPages > max && active < totalPages - (max + 1);

  const handleAnalyticData = (page: number) => {
    const queryParams = new URLSearchParams(location.search);
    context?.setLoaded(false);
    window.scrollTo(0, 0);

    const term = queryParams.get('keywords') ?? '';
    const analyticsData = {
      event: 'page_navigation',
      eventCategory: '[]',
      eventAction: 'page navigation',
      eventLabel: String(page),
      eventParams: {
        page_arrow_value: String(page),
        search_term: term,
        search_page: String(page),
      },
    };
    sendIt(analyticsData);
  };

  const handleQueryParams = (page: number) => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.set('page', `${page}`);

    return queryParams.toString();
  };

  for (let number = start; number <= start + max; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activeDisplay}
        data-page={number}
        href={`?${handleQueryParams(number - 1)}`}
        onClick={() => handleAnalyticData(number - 1)}
        aria-label={`Page ${number}`}
      >
        {number}
      </Pagination.Item>
    );
  }

  if (items.length > 1) {
    return (
      <nav aria-label="Pagination">
        <Pagination size="lg" className="justify-content-center justify-content-sm-end">
          {showFirst && (
            <Pagination.First
              onClick={() => handleAnalyticData(0)}
              href={`?${handleQueryParams(0)}`}
              className="d-none d-md-block"
              aria-label="First Page"
            />
          )}
          {activeDisplay > 1 && (
            <Pagination.Prev
              onClick={() => handleAnalyticData(active - 1)}
              href={`?${handleQueryParams(active - 1)}`}
              aria-label="Previous Page"
            />
          )}
          {showFirst && <Pagination.Ellipsis className="d-none d-md-block" disabled />}
          {items}
          {showLast && <Pagination.Ellipsis className="d-none d-md-block" disabled />}
          {totalPages > activeDisplay && (
            <Pagination.Next
              onClick={() => handleAnalyticData(active + 1)}
              href={`?${handleQueryParams(active + 1)}`}
              aria-label="Next Page"
            />
          )}
          {showLast && (
            <Pagination.Last
              onClick={() => handleAnalyticData(totalPages - 1)}
              href={`?${handleQueryParams(totalPages - 1)}`}
              className="d-none d-md-block"
              aria-label="Last Page"
            />
          )}
        </Pagination>
      </nav>
    );
  }
  return null;
};

export default Pager;
