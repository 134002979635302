import { GatsbyImageProps } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import Link from '../Link';

type Props = {
  drupalId?: string;
  cmsTheme?: CMS_THEME;
  image?: React.ReactElement<GatsbyImageProps> | null;
  link: string;
  title: string;
};

interface CardThemeProps {
  cmsTheme: CMS_THEME;
}

const card = props => <Card as={Link} {...props} />;

const StyledIconCard = styled(card)<CardThemeProps>`
  border: 0px;
  padding: 2rem 0 1rem;
  color: ${(props: CardThemeProps) => theme[props.cmsTheme].text.default};
  text-decoration: none;
  transition: box-shadow 0.3s ease;
  background-color: ${(props: CardThemeProps) => theme[props.cmsTheme].background.paper};
  &:hover,
  &:focus,
  &:active {
    color: ${(props: CardThemeProps) => theme[props.cmsTheme].text.default};
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StyledIconCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  width: 65%;

  & > * + * {
    margin-top: 1.5rem;
  }
`;

const StyledImageContainer = styled.div<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.iconContainer};
  border-radius: 50%;
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;
`;

const IconCard = function ({
  image,
  link,
  title,
  cmsTheme = CMS_THEME.LIGHT,
  drupalId,
  ...props
}: Props) {
  return (
    <AnalyticsPoint
      type="component"
      node={{ title, name: title, drupal_id: drupalId || '', type: 'icon_card' }}
      as={StyledIconCard}
      data-id={drupalId}
      cmsTheme={cmsTheme}
      to={link}
      {...props}
    >
      <StyledIconCardContainer>
        {image && (
          <StyledImageContainer cmsTheme={cmsTheme}>
            {React.cloneElement(image, {
              imgStyle: {
                left: '50%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
              },
              style: {
                height:
                  image?.props?.objectFit === 'contain' ? 'calc(50% * 1.4142 - 10px)' : '100%', // Circle inscribed with a square with a 10px margin.
                left: '50%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: image?.props?.objectFit === 'contain' ? 'calc(50% * 1.4142 - 10px)' : '100%',
              },
            })}
          </StyledImageContainer>
        )}
        <h3 className="d-flex flex-column flex-grow-1 justify-content-center typography_body fw-light text-center">
          {title}
        </h3>
      </StyledIconCardContainer>
    </AnalyticsPoint>
  );
};

export default IconCard;
